import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ZoomMtg } from '@zoomus/websdk';
import * as CryptoJS from 'crypto-js';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.2.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('pt-PT');
ZoomMtg.i18n.reload('pt-PT');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('btnZoom') btnZoom: ElementRef<HTMLElement> | any;
  role = 0;
  leaveUrl = 'https://zoom.assembleiav.com.br';
  jsonObject: any;
  myTimeout: any;
  constructor(private route: ActivatedRoute, public httpClient: HttpClient) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.zoomObj) {
        var bytes = CryptoJS.AES.decrypt(
          JSON.parse(params.zoomObj),
          'visualZoom'
        );
        this.jsonObject = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let el: HTMLElement = this.btnZoom.nativeElement;
        this.myTimeout = setTimeout(function () {
          el.click();
        }, 3000);
      }
    });
  }

  startMeeting(data: any) {
    console.log(data);
    clearTimeout(this.myTimeout);
    const div = document.getElementById('zmmtg-root');
    if (div) div.style.display = 'block';
    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      success: (success: any) => {
        ZoomMtg.join({
          signature: data.signature,
          meetingNumber: data.meetingNumber,
          userName: data.userName,
          apiKey: data.apiKey,
          userEmail:
            data.userEmail != null ? data.userEmail : 'visual@visual.com.br',
          passWord: data.password,
          success: (success: any) => {
            console.log(success);
          },
          error: (error: any) => {
            console.log(error);
          },
        });
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }
}
